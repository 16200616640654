import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import Layout from '../components/layout';
import SEO from '../components/seo';
import styles from '../styles/pages.module.css';

export const query = graphql`
	query {
		vlsiLogo: file(relativePath: { eq: "vlsi.png" }) {
			childImageSharp {
				fluid(maxWidth: 400) {
					...GatsbyImageSharpFluid
				}
			}
		}
		chipionsLogo: file(relativePath: { eq: "chipions.png" }) {
			publicURL
			childImageSharp {
				fluid(maxWidth: 400) {
					...GatsbyImageSharpFluid
				}
			}
		}
	}
`;

export default ({ data }) => (
	<Layout id='chipions'>
		<SEO title='Chipions' keywords={['Chipions']} description='Chipions' image={data.chipionsLogo.publicURL} />
		<div id={styles.chipionsLogos} className='flex flex-wrap m-4 justify-around'>
			<Img fluid={data.chipionsLogo.childImageSharp.fluid} imgStyle={{ objectFit: 'scale-down' }} />
			<Img fluid={data.vlsiLogo.childImageSharp.fluid} imgStyle={{ objectFit: 'scale-down' }} />
		</div>
		<section className='paper p-4 bottom-left bg-black-20'>
			<p>
				A long-term educational program, focusing on the Electronics Engineering field, aiming to achieve it
				through a series of extensive sessions, workshops and labs, covering a wide range of specialties in the
				electronics field in detail, in addition to providing the necessary mentorship for the students who will
				participate in the program throughout its different stages.
			</p>
		</section>
	</Layout>
);
